.formLabel {
    margin-top: 1em;
    margin-bottom: .2em;
    color: #333;
}

.btnCreate {
    margin: 2em 0;
}
.Footer {
    background-color: #fcfcfc!important;
  }
  
.footer-text {
    color: #888;
    width: 100%;
    font-size: small;
    text-align: center;
}

.Navbar-logo {
    margin-right: 0.3em;
    border-radius: 4px;
}