.btnAdd {
  margin-top: 1em;
  margin-bottom: 5em;
  height: 55px;
  width: 174px;
}

.qrBox {
  margin-top: 2em;
  margin-bottom: 1em;
  padding: 1em 0;
}